import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { UserData } from "../models/types";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Box, Grid, Typography } from "@material-ui/core";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { FormattedMessage, useIntl } from "react-intl";
import LocalHelmet from "./Helmet";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    about: {
      textAlign: "left",
      alignSelf: "center",
      width: "100%",
      paddingTop: "10px",
      maxWidth: "1200px",
      margin: "0 auto",
      lineHeight: "25px",
      color: "#333333",
    },
    titleBig: {
      paddingBottom: 15,
      fontWeight: "bold",
      fontSize: "20px",
    },
    text: {
      fontSize: "14px",
      lineHeight: "25px",
      color: "#000000",
    },
    titleContacts: {
      textAlign: "left",
      fontSize: "24px",
      fontWeight: 800,
      color: "#da2232",
      lineHeight: "180%",
      paddingTop: "20px",
    },
    name: {
      fontSize: "20px",
      color: "#333333",
      fontWeight: 600,
      lineHeight: "170%",
    },
    title: {
      fontSize: "16px",
      color: "#333333",
      lineHeight: "140%",
    },
  })
);

interface PropsFromState {
  token: UserData["token"];
}

interface propsFromDispatch {}

type AllProps = PropsFromState & propsFromDispatch;

const Contacts: React.FC<AllProps> = ({ token }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [currentLocation] = React.useState({
    lat: 56.9496,
    lng: 24.1052,
  });

  const onMapLoad = (map: any) => {};

  return (
    <Box alignItems="center" display="block">
      <LocalHelmet
        title={
          intl.formatMessage({ id: "title" }) +
          " - " +
          intl.formatMessage({ id: "contacts" })
        }
      />
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY || ""}
        libraries={["places"]}
      >
        <Box className={classes.about}>
          <Grid container className={classes.about} spacing={9}>
            <Grid md={12} className={classes.titleBig}>
              <FormattedMessage id="contacts" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <b>RĪGA</b>
              <br />
              Kroņu iela 11
              <br />
              +371 28 681 000
              <br />
              kronu@stats.lv
              <br />
              <br />
              Kārļa Ulmaņa gatve 82
              <br />
              +371 26 460 242
              <br />
              remtes@stats.lv
              <br />
              <br />
              <b>JELGAVA</b>
              <br />
              Aviācijas iela 18a
              <br />
              +371 28 625 373
              <br />
              jelgava@stats.lv
              <br />
              <br />
              <b>VALMIERA</b>
              <br />
              Patversmes iela 9<br />
              +371 26 468 877
              <br />
              valmiera@stats.lv
              <br />
              <br />
            </Grid>
            <Grid item xs={12} sm={9}>
              <GoogleMap
                id="search-box-example"
                mapContainerStyle={{
                  minHeight: "400px",
                  maxHeight: "600px",
                  minWidth: "300px",
                  maxWidth: "800px",
                }}
                zoom={7}
                center={currentLocation}
                onLoad={(map) => onMapLoad(map)}
              >
                <Marker
                  key="place"
                  icon={{ url: "/images/marker.svg" }}
                  position={{ lat: 57.5476819, lng: 25.3877044 }}
                />
                <Marker
                  key="place"
                  icon={{ url: "/images/marker.svg" }}
                  position={{ lat: 56.6639256, lng: 23.7678989 }}
                />
                <Marker
                  key="place"
                  icon={{ url: "/images/marker.svg" }}
                  position={{ lat: 56.9618395, lng: 24.1574013 }}
                />
                <Marker
                  key="place"
                  icon={{ url: "/images/marker.svg" }}
                  position={{ lat: 56.9602166, lng: 24.1526172 }}
                />
                <Marker
                  key="place"
                  icon={{ url: "/images/marker.svg" }}
                  position={{ lat: 56.928134, lng: 24.0487683 }}
                />
              </GoogleMap>
              <Box pt={3}>
                <b>STATS RENT SIA</b>
                <br />
                <b>Reģistrācijas Nr.:</b> 40003711680
                <br />
                <b>PVN reģ.Nr.:</b> LV40003711680 
                <br />
                <b>Juridiskā adrese:</b> Kroņu iela 11, Rīga, LV-1035
                <br />
                <br />
                <b> Norēķinu rekvizīti</b>
                <br />
                AS „Citadele”, kods: PARXLV22, konts: LV88PARX0012546150002
                <br />
                AS „SEB banka”, kods: UNLALV2X, konts: LV60UNLA0050005303440
                <br />
                AS “Swedbank”, kods: HABALV22, konts: LV21HABA0551009358684
                <br />
                AS “Luminor”, kods: RIKOLV2X, konts: LV49RIKO0002930266112
                <br />
                <br />
              </Box>
              <Box pt={1}>
                <Grid container>
                  <Grid item md={12} className={classes.titleContacts}>
                    PĀRDOŠANAS NODAĻA
                  </Grid>
                  <Grid item md={6}>
                    <Typography className={classes.name}>
                      Aija Zemīte
                    </Typography>
                    <Typography className={classes.title}>
                      Projektu vadītāja
                    </Typography>
                    <a className={classes.text} href="tel:+371 26 460 277">
                      +371 26 460 277
                    </a>
                    <br />
                    <a
                      className={classes.text}
                      href="mailto:aija.zemite@stats.lv"
                    >
                      aija.zemite@stats.lv
                    </a>
                  </Grid>
                  <Grid item md={6}>
                    <Typography className={classes.name}>
                      Dainis Rimicāns
                    </Typography>
                    <Typography className={classes.title}>
                      Projektu vadītājs
                    </Typography>
                    <a className={classes.text} href="tel:+371 26 462 878">
                      +371 26 462 878
                    </a>
                    <br />
                    <a
                      className={classes.text}
                      href="mailto:dainis.rimicans@stats.lv"
                    >
                      dainis.rimicans@stats.lv
                    </a>
                  </Grid>
                  <Grid item md={12} className={classes.titleContacts}>
                    REMONTA NODAĻA
                  </Grid>
                  <Grid item md={6}>
                    <Typography className={classes.name}>
                      Sergejs Plešņa
                    </Typography>
                    <Typography className={classes.title}>
                      Remonta nodaļas vadītājs
                    </Typography>
                    <a className={classes.text} href="tel:+371 26 460 130">
                      +371 26 460 130
                    </a>
                    <br />
                    <a
                      className={classes.text}
                      href="mailto:sergejs.plesna@stats.lv"
                    >
                      sergejs.plesna@stats.lv
                    </a>
                  </Grid>
                  <Grid item md={6}></Grid>
                  <Grid item md={12} className={classes.titleContacts}>
                    ADMINISTRĀCIJA
                  </Grid>
                  <Grid item md={6}>
                    <Typography className={classes.name}>
                      Vitauts Ivanausks
                    </Typography>
                    <Typography className={classes.title}>
                      Valdes loceklis
                    </Typography>
                    <a className={classes.text} href="tel:+371 26 460 333">
                      +371 26 460 333
                    </a>
                    <br />
                    <a
                      className={classes.text}
                      href="mailto:vitauts.ivanausks@stats.lv"
                    >
                      vitauts.ivanausks@stats.lv
                    </a>
                  </Grid>
                  <Grid item md={6}>
                    <Typography className={classes.name}>
                      Gerda Zaščinska
                    </Typography>
                    <Typography className={classes.title}>
                      Valdes locekle
                    </Typography>
                    <a className={classes.text} href="tel:+371 26 463 309">
                      +371 26 463 309
                    </a>
                    <br />
                    <a
                      className={classes.text}
                      href="mailto:gerda.zascinska@stats.lv"
                    >
                      gerda.zascinska@stats.lv
                    </a>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </LoadScript>
    </Box>
  );
};

const mapStateToProps = ({ user }: ApplicationState) => ({
  token: user.user["token"],
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
